<template>
  <span>{{ result }}</span>
</template>

<script setup>
import { selectionFilterConfig } from '~/constants/skinmkt-filter-config'
const { t } = useI18n()
const $t = t
const props = defineProps({
  type: {
    type: String,
    required: true
  },
  val: {
    type: [String, Number],
    required: true
  },
  forceData: {
    type: String,
    default: ''
  }
})
const result = ref('')
const Obj = {
  dayType: {
    1: $t('1日'),
    2: $t('7日'),
    3: $t('15日'),
    4: $t('30日')
  },
  include: {
    true: $t('排除'),
    false: $t('包含')
  },
  range: {
    between: $t('介于'),
    greater: $t('大于'),
    less: $t('小于')
  },
  platform: {
    ALL: $t('全部'),
    YOUPIN: $t('悠悠'),
    BUFF: $t('BUFF'),
    C5: $t('C5')
  },
  labelText: {
    platform: $t('数据参考'),
    sellNums: $t('在售量'),
    price: $t('在售价格'),
    transactionCount: $t('成交量'),
    transactionCountRiseFallValue: $t('成交量涨跌额'),
    transactionCountRiseFall: $t('成交量涨跌幅'),
    diff1Day: $t('涨跌幅度'),
    increasePrice: $t('涨跌金额'),
    type: $t('类型'),
    quality: $t('类别'),
    exterior: $t('外观'),
    sellPrice: $t('在售价'),
    sellPriceRiseFall: $t('在售价涨跌幅'),
    sellPriceRiseFallValue: $t('在售价涨跌额'),
    sellNumber: $t('在售数'),
    sellCountRiseFall: $t('在售数涨跌幅'),
    sellCountRiseFallValue: $t('在售数涨跌数'),
    biddingPrice: $t('求购价'),
    biddingPriceRiseFall: $t('求购价涨跌幅'),
    biddingPriceRiseFallValue: $t('求购价涨跌额'),
    biddingCount: $t('求购数'),
    biddingCountRiseFall: $t('求购数涨跌幅'),
    biddingCountRiseFallValue: $t('求购数涨跌数')
  }
}

for (const firstClass of selectionFilterConfig(useI18n()).baseKeyList) {
  const tempObj = {}
  const baseLabel = firstClass.label
  tempObj[firstClass.key] = baseLabel

  Object.assign(Obj.labelText, tempObj)
  for (const secondClass of firstClass.apiPrefixList) {
    if (secondClass.label) {
      const tempObj = {}
      tempObj[`${secondClass.key}${firstClass.key}`] = `${secondClass.label}${firstClass.label}`

      Object.assign(Obj.labelText, tempObj)
    }
  }
  for (const secondClass of firstClass.apiSuffixList) {
    if (secondClass.label) {
      const tempObj = {}
      tempObj[`${firstClass.key}${secondClass.key}`] = `${firstClass.label}${secondClass.label}`

      Object.assign(Obj.labelText, tempObj)
    }
  }
}

if (Obj[props.type] && Obj[props.type][props.val]) {
  result.value = Obj[props.type][props.val]
} else {
  result.value = `error 没有找到 ${props.type} ${props.val} 的对应文案`
}
watch(props.val, val => {
  console.log('code2label', val)
  if (Obj[props.type] && Obj[props.type][val]) {
    result.value = Obj[props.type][val]
  } else {
    result.value = `error 没有找到 ${props.type} ${val} 的对应文案`
  }
})
</script>

<style lang="scss" scoped></style>
