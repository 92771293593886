<template>
  <div class="items-container">
    <div class="items-menu flex h-72 items-center justify-between bg-white pl-5 pr-20">
      <el-tabs v-model="activeName" @tab-change="handleChange">
        <el-tab-pane :label="$t('价格涨跌榜')" name="increasePrice"></el-tab-pane>
        <el-tab-pane :label="$t('成交排行榜')" name="transactionCount"></el-tab-pane>
        <el-tab-pane :label="$t('挂刀最优榜')" name="knifeRatio"></el-tab-pane>
      </el-tabs>
      <div class="flex justify-between">
        <div class="keyword-container flex items-center">
          <ClientOnly>
            <el-autocomplete
              v-model="pageQuery.queryContent"
              class="m-2"
              :placeholder="$t('输入饰品名称')"
              width="270"
              clearable
              popper-class="my-suggestion-popper"
              :trigger-on-focus="false"
              :fetch-suggestions="querySearch"
              @clear="getPage('reset')"
              @keyup.enter="getPage('reset')"
              @select="handleSelect"
            >
              <template #suffix>
                <div
                  class="flex h-40 w-36 cursor-pointer items-center justify-center rounded-[10px] bg-blue"
                  style="border-top-left-radius: 0; border-bottom-left-radius: 0"
                  @click="getPage('reset')"
                >
                  <i class="iconfont icon-sousuo1 text-20 text-white"></i>
                </div>
              </template>

              <template #default="{ item }">
                <div class="flex items-center">
                  <img :src="item.url" alt="" style="width: 40px" /> <span class="ml-5 text-14 text-gray-light">{{ item.name }}</span>
                </div>
              </template>
            </el-autocomplete>
          </ClientOnly>

          <!-- <div class="filter-btn ml-20 mr-10 flex cursor-pointer items-center" @click="showFilter = !showFilter">
            <el-badge :value="showFilterData.length" class="item" type="primary">
              <i class="iconfont icon-shaixuan mr-8 text-20 text-gray-light"></i>
            </el-badge>
            <span class="text-14 text-gray-light">{{ $t('筛选') }} </span>
          </div> -->
          <!-- <el-badge :value="showFilterData.length" class="item ml-10" type="primary"> -->
          <el-button type="primary" size="default" class="ml-10 rounded-[10px]" style="height: 40px; border-radius: 10px" @click="showFilter = !showFilter">
            <i class="iconfont icon-shaixuan mr-8 text-20"></i>
            <span class="mr-5 text-14">{{ $t('筛选') }} </span>
            <span>({{ showFilterData.length }}) </span>
          </el-button>
          <!-- </el-badge> -->

          <el-dropdown class="ml-10" popper-class="my-el-dropdown3" trigger="click">
            <div class="el-dropdown-link">
              <div class="flex items-center">
                <mytooltip placement="bottom">
                  <i class="iconfont icon-changjianwenti cursor-pointer text-16 hover:text-blue"></i>
                  <template #content>
                    <div class="text-color-lightgray w-295 py-20 text-14">
                      {{ $t('根据数据周期选择，将详细展示该周期内饰品的新增数量、涨跌金额、涨跌幅度、成交量、量比及换手率等重要数据。') }}
                      {{ $t('但不会影响存世量、持有人数、挂刀比例及在售价格这些实时类型的数据') }}
                    </div>
                  </template>
                </mytooltip>
                <span class="ml-5 mr-5">{{ $t('数据周期') }}</span>
                <span>{{ $t(dayData.label) }}</span>
              </div>

              <i class="arrow"></i>
            </div>

            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="(day, i) in dayList" :key="i" @click.stop="changeDay(day)">
                  <p>{{ $t(day.label) }}</p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <!-- <div class="ml-10 flex items-center">
          <p class="tag" :class="pageQuery.dataRange === 1 ? 'active' : ''" @click="changeDay(1)">{{ $t('1日') }}</p>
          <p class="tag" :class="pageQuery.dataRange === 2 ? 'active' : ''" @click="changeDay(2)">{{ $t('7日') }}</p>
          <p class="tag" :class="pageQuery.dataRange === 3 ? 'active' : ''" @click="changeDay(3)">{{ $t('15日') }}</p>
          <p class="tag" :class="pageQuery.dataRange === 4 ? 'active' : ''" @click="changeDay(4)">{{ $t('30日') }}</p>
        </div> -->
      </div>
    </div>
    <div v-show="showFilter" class="filter-container">
      <div class="mx-10 bg-blue-50 px-10 py-20">
        <div :key="resetKey" class="flex flex-wrap justify-between">
          <commonFilterItem
            v-for="filter of commonFilterList"
            :key="filter.filterKey"
            ref="commonFilterItemRef1"
            :filter-data="filter.filterData"
            :default-data="filter.defaultData"
            :filter-key="filter.filterKey"
            :label="filter.label"
            :type="filter.type"
            :width="filter.width"
            :reset-switch="resetSwitch"
            :input-suffix="filter.inputSuffix"
            @update-data="filterEmit"
          ></commonFilterItem>
          <div v-for="filter of filterObj" :key="filter.searchKey">
            <commonFilterItem
              ref="commonFilterItemRef"
              :filter-data="filter"
              :defalut-data="{}"
              :label="filter.name"
              :type="'include'"
              :width="410"
              :filter-key="`backend-${filter.searchKey}`"
              :reset-switch="resetSwitch"
              @update-data="filterEmit"
            ></commonFilterItem>
          </div>
        </div>
        <div class="flex justify-end">
          <el-button type="primary" plain class="filter-button w-110 text-14" @click="resetFilter">{{ $t('重置') }}</el-button>
          <el-button type="primary" class="filter-button w-110 text-14" @click="searchFilter">{{ $t('确认') }}</el-button>
        </div>
      </div>
    </div>

    <div class="filter-param flex flex-wrap items-center bg-white px-15 py-15">
      <div v-for="(item, index) in showFilterData" :key="index" class="param-item mx-5 my-5 flex cursor-pointer items-center text-gray-light hover:text-blue">
        <div class="mr-10 flex items-center leading-none">
          <template v-if="item.type === 'single'">
            <codeToLabel :key="`${item.labelText.key}${item.labelText.val}`" :type="item.labelText.key" :val="item.labelText.val" />：
            <codeToLabel :key="`${item.platform.key}${item.platform.val}`" :type="item.platform.key" :val="item.platform.val" />
          </template>
          <template v-if="item.type === 'range'">
            <codeToLabel :key="`${item.labelText.key}${item.labelText.val}`" :type="item.labelText.key" :val="item.labelText.val" />：
            <codeToLabel v-if="item.range.val" :key="`${item.range.key}${item.range.val}`" :type="item.range.key" :val="item.range.val" />
            <span v-if="item.range.val === 'between'">{{ item.minVal }} - {{ item.maxVal }}</span>
            <span v-if="item.range.val === 'greater'">
              {{ item.minVal }}
            </span>
            <span v-if="item.range.val === 'less'">
              {{ item.maxVal }}
            </span>
            <span v-if="item.dayType.val"> (<codeToLabel :key="`${item.dayType.key}${item.dayType.val}`" :type="item.dayType.key" :val="item.dayType.val" />) </span>
          </template>
          <template v-else-if="item.type === 'include'">
            <codeToLabel :key="`${item.labelText.key}${item.labelText.val}`" :type="item.labelText.key" :val="item.labelText.val" />：
            <codeToLabel :key="`${item.include.key}${item.include.val}`" :type="item.include.key" :val="item.include.val" />
            <span class="ml-5 inline-block max-w-100 truncate">{{ item.includeText }}</span>
          </template>
        </div>
        <i v-if="item.type !== 'single'" class="iconfont icon-guanbi1 text-10 leading-none" @click="deleteFilterItem(item)"></i>
      </div>
      <el-button class="param-button text-color-lightgray mx-5 my-5 w-110 text-14" @click="resetFilter">{{ $t('重置') }}</el-button>
    </div>

    <div class="items-content bg-white">
      <div class="items-table-title relative mx-20 flex py-10">
        <div class="items-table-c1">{{ $t('饰品') }}</div>
        <div class="items-table-c2">
          <el-row class="cursor-pointer" align="middle" @click.stop="changeSort('surviveNum')">
            <mytooltip placement="bottom" :content="$t('可交易（包含交易冷却中）的饰品总量')">
              <span class="cursor-pointer" :class="sortData['surviveNum'] ? 'text-color-orange' : ''">{{ $t('存世量') }}</span>
            </mytooltip>
            <sortarrow :sort-type="sortData['surviveNum']" />
          </el-row>
        </div>
        <div class="items-table-c3 flex items-center" @click.stop="changeSort('holdersNum')">
          <mytooltip placement="bottom" :content="$t('拥有该类饰品的玩家数量')">
            <span class="cursor-pointer" :class="sortData['holdersNum'] ? 'text-color-orange' : ''">{{ $t('持有人数') }}</span>
          </mytooltip>
          <sortarrow :sort-type="sortData['holdersNum']" />
        </div>
        <div class="items-table-c4 flex items-center" @click.stop="changeSort('addNum')">
          <mytooltip placement="bottom" :content="$t('开箱、汰换、游戏掉落的方式产生的数量')">
            <span class="cursor-pointer" :class="sortData['addNum'] ? 'text-color-orange' : ''">{{ $t('新增量') }}</span>
          </mytooltip>
          <sortarrow :sort-type="sortData['addNum']" />
        </div>
        <div class="items-table-c5">
          <el-row class="cursor-pointer" align="middle" @click.stop="changeSort('transactionCount')">
            <mytooltip placement="bottom" :content="$t('追踪全球用户库存的变动，精准分析得出成交量')">
              <span class="cursor-pointer" :class="sortData['transactionCount'] ? 'text-color-orange' : ''">{{ $t('成交量') }}</span>
            </mytooltip>

            <sortarrow :sort-type="sortData['transactionCount']" />
          </el-row>
        </div>
        <div class="items-table-c6">
          <el-row align="middle" @click="changeSort('volumeRatio')">
            <mytooltip placement="bottom">
              <span class="cursor-pointer" :class="sortData['volumeRatio'] ? 'text-color-orange' : ''">{{ $t('量比') }}</span>

              <template #content>
                <div class="py-15 text-14 text-gray-light">
                  <p>{{ $t('近24小时的成交量与过去5日平均成交量之比。') }}</p>
                  <p>{{ $t('量比数值越大，表明近24小时该皮肤交易越频繁') }}</p>
                </div>
              </template>
            </mytooltip>
            <sortarrow :sort-type="sortData['volumeRatio']" />
          </el-row>
        </div>
        <div class="items-table-c7">
          <el-row class="cursor-pointer" align="middle">
            <mytooltip placement="bottom" :content="$t('换手率 = 成交量 / 存世量')">
              <span class="cursor-pointer" :class="sortData['turnoverRate'] ? 'text-color-orange' : ''">{{ $t('换手率') }}</span>
            </mytooltip>

            <!-- <sortarrow :sort-type="sortData['turnoverRate']" /> -->
          </el-row>
        </div>

        <div class="items-table-c8">
          <el-row class="cursor-pointer" align="middle" @click.stop="changeSort(knifeRatioSort)">
            <mytooltip placement="bottom">
              <span class="cursor-pointer" :class="sortData[knifeRatioSort] ? 'text-color-orange' : ''">{{ $t('挂刀比例') }}</span>
              <template #content>
                <div class="text-color-gray py-10 text-14">
                  <div>{{ $t('寄售最优：以平台最低售价购入，以 Steam市场最低寄售价卖出') }}</div>
                  <div class="text-color-gray">{{ $t('求购最优：以平台最低售价购入，以 Steam市场最高求购价卖出') }}</div>
                  <div class="text-color-gray">{{ $t('求购稳定：以平台最低售价购入，以 Steam市场最高求购价第三位的价格卖出') }}</div>
                </div>
              </template>
            </mytooltip>
            <sortarrow :sort-type="sortData[knifeRatioSort]" />
          </el-row>
        </div>

        <div class="items-table-c9">{{ $t('在售价格') }}</div>
        <!-- <div class="items-table-c10">
          <el-tooltip class="box-item" effect="dark" content="采集平台中最低在售价的走势"> 价格走势 </el-tooltip>
        </div>
        <div class="items-table w-70">
          <el-row class="cursor-pointer text-right" align="middle" justify="end" @click.stop="changeSort('increasePrice')">
            涨幅
            <sortarrow :sort-type="sortData['increasePrice']" />
          </el-row>
        </div> -->
        <div class="items-table-c10 flex items-center" @click="changeSort('increasePrice')">
          <span class="cursor-pointer" :class="sortData['increasePrice'] ? 'text-color-orange' : ''"
            >{{ $t('涨跌金额') }}<span class="mr-5">({{ monetaryUnit() }})</span></span
          >

          <sortarrow class="mr-5" :sort-type="sortData['increasePrice']" />
        </div>
        <div class="items-table-c11 flex items-center" @click="changeSort(readDayParam)">
          <mytooltip placement="bottom" :content="$t('交易平台中最低在售价的走势')">
            <span class="cursor-pointer" :class="sortData[readDayParam] ? 'text-color-orange' : ''">{{ $t('价格涨幅') }}</span>
          </mytooltip>
          <sortarrow :sort-type="sortData[readDayParam]" />
        </div>

        <el-popover popper-class="" placement="bottom-end" :visible="popverVisible" :show-arrow="false" :width="440">
          <template #reference>
            <i class="iconfont icon-bianzu absolute right-0 top-12 cursor-pointer leading-none text-gray-light hover:text-blue" @click="popverVisible = true"></i>
          </template>

          <template #default>
            <div class="flex items-center justify-between border-b border-solid border-[#EFF1F5] pb-12">
              <span class="text-14 font-bold text-black">{{ $t('列表设置') }}</span>
              <i class="iconfont icon-guanbi1 text-16 text-gray-light" @click="popverVisible = false"></i>
            </div>

            <div class="mt-12 pt-10 text-14">
              <span class="font-bold text-black">{{ $t('在售价格展示平台') }}</span>
              <span class="ml-10 text-gray-light">{{ $t('最多同时展示4个平台') }}</span>
            </div>

            <div>
              <el-checkbox-group v-model="platformList" :min="1" :max="4" @change="changePlatform">
                <el-checkbox v-for="platform in platformDefaultList" :key="platform.value" :label="platform.value" :class="['w-70']">
                  {{ platform.label }}
                </el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="mb-12 mt-20 text-14">
              <span class="font-bold text-black">{{ $t('挂刀比例排序依据') }}</span>
              <span class="ml-10 text-gray-light">{{ $t('3选1') }}</span>
            </div>

            <div>
              <el-radio-group v-model="knifeRatioSort" @change="changeKnifeRatio">
                <el-radio value="purchaseBest">{{ $t('求购最优') }}</el-radio>
                <el-radio value="purchaseStable">{{ $t('求购稳定') }}</el-radio>
                <el-radio value="consignmentBest">{{ $t('寄售最优') }}</el-radio>
              </el-radio-group>
            </div>

            <div class="mt-40 flex justify-end">
              <el-button style="width: 130px; height: 40px" @click="resetPopoverData">{{ $t('重置') }}</el-button>
              <el-button style="width: 130px; height: 40px" type="primary" @click="popverVisible = false">{{ $t('确认') }}</el-button>
            </div>
          </template>
        </el-popover>
      </div>
      <el-skeleton style="width: 1400px" :loading="pageLoading" animated :count="10">
        <template #template>
          <div class="my-10 flex pl-20">
            <el-skeleton-item variant="rect" style="width: 300px; height: 70px" />
            <div class="ml-50 flex w-1000 items-center">
              <el-skeleton-item variant="text" style="margin-right: 16px" />
              <!-- <el-skeleton-item variant="text" style="width: 30%" /> -->
            </div>
            <!-- <div style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px">
              <el-skeleton-item variant="text" style="margin-right: 16px" />
              <el-skeleton-item variant="text" style="width: 30%" />
            </div> -->
          </div>
        </template>
        <template #default>
          <div v-if="pageData && pageData[0]" class="items-table-list">
            <div
              v-infinite-scroll="loadList"
              :infinite-scroll-disabled="pageLoading || !pageQuery.nextId || loadTime >= 2"
              :infinite-scroll-delay="500"
              :infinite-scroll-immediate="false"
            >
              <div v-for="(item, index) in pageData" :key="index" class="items-table-item flex items-center px-20 text-16">
                <NuxtLink :to="localePath(`/cs2/${encodeURIComponent(item.marketHashName)}`)" target="_blank">
                  <div class="items-table-c1 flex">
                    <div class="item-img mr-10 flex h-75 w-100 items-center justify-center rounded-[10px]" style="min-width: 100px">
                      <NuxtImg :src="item.imageUrl" width="80" class="w-80" format="webp" loading="lazy" style="max-width: auto" />
                    </div>
                    <div class="flex items-center">
                      <div>
                        <div class="mb-8 w-160 truncate text-16">
                          {{ item.shortName.replace(/(\(|（)[^()（）]*(\)|）)/g, '') }}
                        </div>
                        <div class="text-12">
                          <ClientOnly>
                            <span class="mr-10" :style="{ color: item?.exterior?.nameZh ? CS_EXTERIOR[item.exterior.nameZh].color : '' }">{{ item?.exterior?.nameZh }}</span>

                            <span
                              v-if="item.quality.nameZh != '普通'"
                              class="text-color-st"
                              :style="{ color: item?.exterior?.nameZh ? CS_EXTERIOR[item.exterior.nameZh].color : '' }"
                              >{{ item?.quality?.nameZh }}</span
                            >
                          </ClientOnly>
                        </div>
                      </div>
                    </div>
                  </div>
                </NuxtLink>
                <div class="items-table-c2">
                  <!-- 存世量 -->
                  {{ item.surviveNum !== null ? item.surviveNum : '-' }}
                  <!-- {{ item.totalTradeNum }} -->
                </div>
                <div class="items-table-c3">
                  <!-- 持有人数 -->
                  {{ item.holdersNum !== null ? item.holdersNum : '-' }}
                  <!-- {{ item.transactionCount }} -->
                </div>
                <!-- 今日增量 -->
                <div class="items-table-c4">
                  {{ item.addNum !== null ? item.addNum : '-' }}
                  <arrow />
                </div>
                <!-- 今日成交量 -->
                <div class="items-table-c5">
                  {{ item.transactionCount !== null ? item.transactionCount : '-' }}
                  <!-- {{ item.turnoverRate.toFixed(2) <= 0.01 ? '<0.01%' : item.turnoverRate.toFixed(2) }}%
                  <arrow :direct="item.turnoverRateIsRise" /> -->
                </div>
                <!-- 量比 -->
                <div class="items-table-c6 text-color-green">
                  {{ item.volumeRatio > 0 ? item.volumeRatio : '-' }}
                  <!-- {{ item.surviveNum }}
                  <arrow :direct="item.holdersNumIsRise" /> -->
                </div>
                <!-- 换手率 -->
                <div class="items-table-c7">
                  <span v-if="item.turnoverRate === null">-</span>
                  <span v-else>{{ item.turnoverRate.toFixed(2) <= 0.01 ? '<0.01' : item.turnoverRate.toFixed(2) }}%</span>

                  <arrow v-if="item.turnoverRate" :direct="item.turnoverRateIsRise" />
                </div>
                <!-- 挂刀比例 -->
                <div class="items-table-c8 text-14">
                  <div class="mb-10">
                    <span class="cursor-pointer">{{ $t('寄售最优') }} </span>

                    <span
                      :style="{
                        color: suspensionColor(item.consignmentBest)
                      }"
                      >{{ item.consignmentBest ? item.consignmentBest : '-' }}</span
                    >
                  </div>
                  <div class="mb-10">
                    <span class="cursor-pointer">{{ $t('求购最优') }}</span>

                    <span
                      :style="{
                        color: suspensionColor(item.purchaseBest)
                      }"
                      >{{ item.purchaseBest ? item.purchaseBest : '-' }}</span
                    >
                  </div>
                  <div>
                    <span class="cursor-pointer">{{ $t('求购稳定') }}</span>

                    <span
                      :style="{
                        color: suspensionColor(item.purchaseStable)
                      }"
                      >{{ item.purchaseStable ? item.purchaseStable : '-' }}</span
                    >
                  </div>
                </div>
                <div class="items-table-c9 text-color-gray">
                  <platformLowestPrice :size="'16px'" :list="item.sellingPriceList" />
                  <!-- {{ item.holdersNum }}
                  <arrow :direct="item.holdersNumIsRise"></arrow> -->
                </div>
                <!-- 涨跌金额 -->
                <div class="items-table-c10">
                  <NuxtLink :to="localePath(`/cs2/${encodeURIComponent(item.marketHashName)}?type=charts&platfrom=ALL&days=1`)" target="_blank">
                    <span :class="item.increasePrice > 0 ? 'text-color-red' : 'text-color-green'"> {{ item.increasePrice > 0 ? '+' : '' }}{{ item.increasePrice }} </span>
                  </NuxtLink>
                </div>
                <div class="items-table-c11">
                  <ClientOnly>
                    <NuxtLink :to="localePath(`/cs2/${encodeURIComponent(item.marketHashName)}?type=charts&platfrom=ALL&days=1`)" target="_blank">
                      <indexChart
                        :id="`chart-${item?.id}`"
                        class="inline-block"
                        :data="item?.trendList"
                        :direction="item[readDayParam]"
                        :x-key="0"
                        :y-key="1"
                        :class-name="'trend-chart'"
                        :last-day-end="true"
                      ></indexChart>
                    </NuxtLink>
                  </ClientOnly>
                  <mytooltip :offset="4" placement="bottom">
                    <template #default>
                      <div :class="[item[readDayParam] > 0 ? 'text-color-red' : 'text-color-green', 'text-center', 'w-70']">
                        {{ item[readDayParam] > 0 ? '+' : '' }}{{ item[readDayParam] }}%
                      </div>
                    </template>

                    <template #content>
                      <div class="w-135 py-15 text-14">
                        <p class="text-gray-light">{{ $t('价格变化幅度') }}</p>
                        <p class="mt-5 flex items-center justify-between">
                          <span class="text-gray-light">{{ $t('近7天') }}：</span>
                          <span :class="item.diff7Day > 0 ? 'text-color-red' : 'text-color-green'"> {{ item.diff7Day > 0 ? '+' : '' }}{{ item.diff7Day }} </span>
                        </p>
                        <p class="mt-5 flex items-center justify-between">
                          <span class="text-gray-light">{{ $t('近15天') }}：</span>
                          <span :class="item.diff15Day > 0 ? 'text-color-red' : 'text-color-green'"> {{ item.diff15Day > 0 ? '+' : '' }}{{ item.diff15Day }} </span>
                        </p>
                        <p class="mt-5 flex items-center justify-between">
                          <span class="text-gray-light">{{ $t('近30天') }}：</span>
                          <span :class="item.diff30Day > 0 ? 'text-color-red' : 'text-color-green'"> {{ item.diff30Day > 0 ? '+' : '' }}{{ item.diff30Day }} </span>
                        </p>
                      </div>
                    </template>
                  </mytooltip>
                </div>
                <!-- <div :class="['items-table', item.increasePrice > 0 ? 'text-color-red' : 'text-color-green', 'text-right', 'w-70']">
                  {{ item.increasePrice > 0 ? '+' : '' }}{{ item.increasePrice }}%
                </div> -->
              </div>
            </div>
          </div>
          <empty v-else />
        </template>
      </el-skeleton>
      <!-- <CommonLoading v-if="pageLoading" /> -->
    </div>

    <!-- <indexDrawer :show-drawer="showDrawer" @close="showDrawer = false" @reset="resetDrawer" @sure="sureDrawer" /> -->
  </div>
</template>

<script setup>
import platformLowestPrice from './platformLowestPrice.vue'
import filterlist from '~/components/common/filterlist.vue'
import sortarrow from '~/components/common/sortarrow.vue'
import arrow from '~/components/common/arrow.vue'
import empty from '~/components/common/empty.vue'
import mytooltip from '~/components/common/mytooltip.vue'
import commonFilterItem from '~/components/common/common-fliter-item-v1.vue'
import codeToLabel from '~/components/common/code2label.vue'
import indexChart from './indexChart.vue'
import indexDrawer from './indexDrawer.vue'
import indexFilterConfig from '~/constants/index-filter-config'
import { dayList } from '~/constants/common'
import { CS_EXTERIOR, CS_QUALITY } from '~/constants/item'
import { formatSortVal, handleTagMap } from '~/utils/index'
import { filterDatav2, indexpageListData, getAutoComletion } from '~/api/steam'
const { t } = useI18n()
const $t = t
const props = defineProps({
  commonDictionary: {
    type: Object,
    default: () => ({})
  }
})

const activeName = ref('transactionCount')
const readDayParam = ref('diff1Day')
const filterRes = await useAsyncData(() => filterDatav2({ appId: 730 }))
const filterObj = filterRes.data._value
const store = useNuxtStore()
let pageQuery = ref({
  pageSize: 20,
  queryContent: '',
  sortList: readSortList(),
  dataRange: 1,
  conditionList: readConditionList(),
  platform: 'ALL'
})
let sortData = ref({
  transactionCount: 'DESC'
})
let pageData = ref([])
let pageLoading = ref(false)
let loadTime = ref(0)
let dayData = reactive({
  label: $t('1天'),
  value: 1
})
getPage('reset')
const selectFilter = ref({})
const viewSelectFilter = ref({})
const filterListRef = ref(null)
const commonFilterItemRef1 = ref(null)
const commonFilterItemRef = ref(null)
const showFilter = ref(false)
let resetKey = ref(0)
let resetSwitch = ref({
  val: false
})
const showFilterData = ref([])
const filtedBackendData = ref({})

const popverVisible = ref(false)
const platformDefaultList = ref([
  {
    label: 'Steam',
    value: 'steam'
  },
  {
    label: 'C5GAME',
    value: 'c5'
  },
  {
    label: '悠悠',
    value: 'youpin'
  },
  {
    label: 'BUFF',
    value: 'buff'
  },
  {
    label: 'WaxPeer',
    value: 'waxpeer'
  },
  {
    label: 'Dmarket',
    value: 'dmarket'
  },
  {
    label: 'CSMoney',
    value: 'csmoney'
  },
  {
    label: 'Skinport',
    value: 'skinport'
  }
])

const knifeRatioSort = useCookie('SDT_KnifeRatioSort', {
  maxAge: 999999999
})
if (!knifeRatioSort.value) {
  knifeRatioSort.value = 'purchaseBest'
}

const platformList = useCookie('SDT_PlatformList', {
  maxAge: 999999999
})

const commonFilterList = [
  {
    filterData: indexFilterConfig.platformDefaultData,
    defaultData: indexFilterConfig.platformDefaultData,
    filterKey: 'platform',
    label: $t('数据参考'),
    type: 'single',
    width: 410
  },
  {
    filterData: indexFilterConfig.sellNumsDefaultData,
    defaultData: indexFilterConfig.sellNumsDefaultData,
    filterKey: 'sellNums',
    label: $t('在售量'),
    type: 'range',
    width: 410
  },
  {
    filterData: indexFilterConfig.transactionCountDefaultData,
    defaultData: indexFilterConfig.transactionCountDefaultData,
    filterKey: 'transactionCount',
    label: $t('成交量'),
    type: 'range',
    width: 410
  },
  {
    filterData: indexFilterConfig.priceDefaultData,
    defaultData: indexFilterConfig.priceDefaultData,
    filterKey: 'price',
    label: $t('在售价格'),
    type: 'range',
    width: 410
  },
  {
    filterData: indexFilterConfig.diff1DayDefaultData,
    defaultData: indexFilterConfig.diff1DayDefaultData,
    filterKey: 'diff1Day',
    label: $t('涨跌幅度'),
    type: 'range',
    width: 410,
    inputSuffix: '%'
  },
  {
    filterData: indexFilterConfig.increasePriceDefaultData,
    defaultData: indexFilterConfig.increasePriceDefaultData,
    filterKey: 'increasePrice',
    label: $t('涨跌金额'),
    type: 'range',
    width: 410
  }
]
if (!platformList.value) {
  platformList.value = ['steam', 'c5', 'youpin', 'buff']
}

watch(selectFilter, val => {
  console.log(val)
})

watch(pageQuery, val => {
  handleShowFilterData()
})

onMounted(() => {
  handleShowFilterData()
})

// 处理展示数据
function handleShowFilterData() {
  showFilterData.value = []

  // 数据参考
  if (pageQuery.value.platform) {
    showFilterData.value.push({
      type: 'single',
      labelText: {
        key: 'labelText',
        val: 'platform'
      },
      platform: {
        key: 'platform',
        val: pageQuery.value.platform
      }
    })
  }

  // 其他
  pageQuery.value.conditionList.forEach((item, index) => {
    let range = ''

    if (item.filterRange || item.minVal || item.maxVal) {
      if (item.minVal && item.maxVal) {
        range = 'between'
      } else if (item.minVal && !item.maxVal) {
        range = 'greater'
      } else if (!item.minVal && item.maxVal) {
        range = 'less'
      }
      if (range) {
        showFilterData.value.push({
          refIndex: index + 1,
          type: 'range',
          dayType: {
            key: 'dayType',
            val: item.filterRange
          },
          range: {
            key: 'range',
            val: range
          },
          minVal: item.minVal,
          maxVal: item.maxVal,
          labelText: {
            key: 'labelText',
            val: item.fieldName
          }
        })
      }
    }
  })

  // 类型
  if (pageQuery.value.weaponList && pageQuery.value.weaponList[0]) {
    let includeText = ''

    pageQuery.value.weaponList.forEach(item => {
      includeText = includeText + filtedBackendData.value.weaponList[item].name + '/'
    })

    showFilterData.value.push({
      refIndex: 0,
      type: 'include',
      labelText: {
        key: 'labelText',
        val: 'type'
      },
      include: {
        key: 'include',
        val: pageQuery.value.weaponInvertSelect.toString()
      },
      includeText: includeText
    })
  }

  // 类别
  if (pageQuery.value.qualityList && pageQuery.value.qualityList[0]) {
    let includeText = ''

    pageQuery.value.qualityList.forEach(item => {
      includeText = includeText + filtedBackendData.value.qualityList[item].name + '/'
    })

    showFilterData.value.push({
      refIndex: 1,
      type: 'include',
      labelText: {
        key: 'labelText',
        val: 'quality'
      },
      include: {
        key: 'include',
        val: pageQuery.value.qualityInvertSelect.toString()
      },
      includeText: includeText
    })
  }

  // 外观
  if (pageQuery.value.exteriorList && pageQuery.value.exteriorList[0]) {
    let includeText = ''

    pageQuery.value.exteriorList.forEach(item => {
      includeText = includeText + filtedBackendData.value.exteriorList[item].name + '/'
    })

    showFilterData.value.push({
      refIndex: 2,
      type: 'include',
      labelText: {
        key: 'labelText',
        val: 'exterior'
      },
      include: {
        key: 'include',
        val: pageQuery.value.exteriorInvertSelect.toString()
      },
      includeText: includeText
    })
  }
  getPage('reset')
  console.log('showFilterData', showFilterData.value)
}

function handleNodeClick(e, searchKey) {
  const tempObj = JSON.parse(JSON.stringify(selectFilter.value))
  for (let key in e) {
    const searchKey = `${e[key].searchKey.toLowerCase()}List`
    if (tempObj[searchKey]) {
      tempObj[searchKey].push(key)
    } else {
      tempObj[searchKey] = [key]
    }
  }

  pageQuery.value[`${searchKey.toLowerCase()}List`] = []
  pageQuery.value = Object.assign(pageQuery.value, tempObj)
  // tempObj[e.searchKey] = e.key
  // pageQuery.value = Object.assign(pageQuery.value, tempObj)
  // pageData.value = []
  // if (e.searchKey == 'Weapon') {
  //   pageQuery.value.type = ''
  // }
  // if (e.searchKey == 'type') {
  //   pageQuery.value.Weapon = ''
  // }
  // getPage('reset')
}

function deleteFilterItem(item) {
  if (item.type === 'range') {
    commonFilterItemRef1.value[item.refIndex].reset({
      minVal: '',
      maxVal: ''
    })
  } else if (item.type === 'include') {
    // 因新增了武器箱等数据的筛选，存在没有二级数据的筛选条件，如没有二级，则需将值传入typeList，固做特殊处理
    if (item.labelText.val === 'type') {
      pageQuery.value.typeList = []
      pageQuery.value.weaponList = []
    }
    commonFilterItemRef.value[item.refIndex].reset()
  }

  handleShowFilterData()
  console.log('delete', item, commonFilterItemRef1)
}

function filterEmit(filterData) {
  console.log('filterEmit', filterData)
  if (filterData.filterKey === 'platform') {
    pageQuery.value.platform = filterData.val.val
  }
  if (filterData.type === 'range') {
    pageQuery.value.conditionList.forEach(item => {
      console.log('item.fieldName', item.fieldName, filterData.filterKey)
      if (item.fieldName === filterData.filterKey) {
        console.log('hit')
        item.minVal = filterData.val.val.minVal
        item.maxVal = filterData.val.val.maxVal
        if (filterData.dayType) {
          item.filterRange = filterData.dayType
        }
      }
    })
  }
  if (filterData.type === 'include') {
    const tempObj = {}
    let searchKey = `${filterData.searchKey.toLocaleLowerCase()}List`
    if (filtedBackendData.value[searchKey]) {
      Object.assign(filtedBackendData.value[searchKey], filterData.val)
    } else {
      filtedBackendData.value[searchKey] = filterData.val
    }
    for (let key in filterData.val) {
      const itemSearchKey = `${filterData.val[key].searchKey.toLocaleLowerCase()}List`
      console.log('key', key, filterData.val[key].searchKey)
      if (tempObj[itemSearchKey]) {
        tempObj[itemSearchKey].push(key)
      } else {
        tempObj[itemSearchKey] = [key]
      }
    }
    pageQuery.value[`${filterData.searchKey.toLowerCase()}InvertSelect`] = filterData.relOp.selected.val == 'declude'
    if (searchKey) {
      pageQuery.value[searchKey] = []
    }

    pageQuery.value = Object.assign(pageQuery.value, tempObj)

    if (filterData.relOp.selected.val === 'declude') {
    }
    console.log('pageQuery.value.conditionList', pageQuery.value)
  }
}
function addPageNum() {
  pageQuery.value.pageNum++
  getPage('load')
}
function getPage(type) {
  if (type === 'reset') {
    // pageData.value = []
    pageQuery.value.nextId = ''
    pageQuery.value.pageNum = 1
    pageLoading.value = true
    loadTime.value = 0
  }
  indexpageListData(pageQuery)
    .then(res => {
      loadTime.value++
      // if (pageData.value && !pageData.value[0]) {
      //   pageData.value = res.data.list;
      // } else {
      //   pageData.value = pageData.value.concat(res.data.list);
      // }
      pageLoading.value = false
      pageQuery.value.nextId = res?.data?.nextId

      if (type === 'load') {
        pageData.value = pageData.value.concat(res?.data?.list)
      } else {
        pageData.value = res?.data?.list
      }

      pageData.value.forEach(item => {
        handleTagMap(item, props.commonDictionary)
      })

      // if (res.refresh) {
      //   res.refresh();
      // }
      const platform = pageQuery.value.platform ? pageQuery.value.platform : 'ALL'
      store.setPlatform(platform)
    })
    .catch(() => {
      pageLoading.value = false
    })
}
function changeSort(curkey) {
  if (curkey === 'purchaseBest' || curkey === 'purchaseStable' || curkey === 'consignmentBest') {
    // 挂刀比例
    sortData.value[curkey] = formatSortVal(sortData.value[curkey])
  } else {
    // 其他
    sortData.value[curkey] = formatSortVal(sortData.value[curkey], 'DESC')
  }

  // 筛选数据处理
  pageQuery.value.sortList = []
  for (let key in sortData.value) {
    const curData = {
      sortField: key,
      sortType: sortData.value[key]
    }

    if (curData.sortType) {
      pageQuery.value.sortList = []
      pageQuery.value.sortList.push(curData)
    }

    if (curkey != key) {
      delete sortData.value[key]
    }
  }

  getPage('reset')
}

// 查询
function searchFilter() {
  pageData.value = []
  showFilter.value = false
  getPage('reset')

  // 测试
  handleShowFilterData()
}

// 重置
function resetFilter() {
  pageQuery.value = {
    pageSize: 20,
    platform: 'ALL',
    queryContent: '',
    sortList: readSortList(),
    dataRange: 1,
    conditionList: readConditionList()
  }

  // resetSwitch.value.val = !resetSwitch.value.val
  // console.log('resetSwitch', resetSwitch.value)
  nextTick(() => {
    resetKey.value++
  })

  if (activeName.value === 'knifeRatio') {
    changeSort(knifeRatioSort.value)
  } else {
    changeSort(activeName.value)
  }
  // getPage('reset')
}

function querySearch(queryString, cb) {
  getAutoComletion({
    content: encodeURIComponent(queryString)
  }).then(res => {
    if (res.data && res.data[0]) {
      cb(res.data)
    }
  })
}

function handleSelect(item) {
  pageQuery.value.queryContent = item.name
  getPage('reset')
}

// tab切换
function handleChange(val) {
  if (val === 'knifeRatio') {
    changeSort(knifeRatioSort.value)
  } else {
    changeSort(val)
  }
}

// 切换排行榜时间范围
function changeDay(day) {
  pageQuery.value.dataRange = day.value
  dayData = day
  getPage('reset')

  switch (Number(day.value)) {
    case 1:
      readDayParam.value = 'diff1Day'
      break
    case 2:
      readDayParam.value = 'diff7Day'
      break
    case 3:
      readDayParam.value = 'diff15Day'
      break
    case 4:
      readDayParam.value = 'diff30Day'
      break
  }
}

// 筛选重置
function resetDrawer() {
  pageQuery.value.sortList = readSortList()
  pageQuery.value.conditionList = readConditionList()
  pageQuery.value.platform = 'ALL'
  showDrawer.value = false
  getPage('reset')
}

// 筛选数据
function sureDrawer(conditionData) {
  pageQuery.value.conditionList = []
  for (let key in conditionData) {
    if (key != 'dataPlatform') {
      const curCondition = Object.assign({}, conditionData[key], {
        fieldName: key
      })
      pageQuery.value.conditionList.push(curCondition)
    }
  }
  pageQuery.value.platform = conditionData.dataPlatform
  showDrawer.value = false

  if (activeName.value === 'knifeRatio') {
    changeSort(knifeRatioSort.value)
  } else {
    getPage('reset')
  }
}

// 获取排序重置数据
function readSortList() {
  let resetList = []

  if (activeName.value === 'knifeRatio') {
    resetList = [{ sortField: knifeRatioSort.value, sortType: 'ASC' }]
  } else {
    resetList = [{ sortField: activeName.value, sortType: 'DESC' }]
  }

  return resetList
}

// 获取条件筛选重置数据
function readConditionList() {
  const tempArr = []
  for (let defalutData in indexFilterConfig) {
    if (defalutData === 'platformDefaultData') {
      continue
    }
    const fieldNameKey = defalutData.split('DefaultData')[0]
    tempArr.push({
      filterRange: indexFilterConfig[defalutData]?.dayType,
      minVal: indexFilterConfig[defalutData]?.val?.minVal,
      maxVal: indexFilterConfig[defalutData]?.val?.maxVal,
      fieldName: fieldNameKey
    })
  }
  return tempArr
  // return [
  //   { minVal: indexFilterConfig.priceDefaultData.val.minVal, maxVal: indexFilterConfig.priceDefaultData.val.maxVal, fieldName: 'price' },
  //   { filterRange: 1, minVal: '', maxVal: '', fieldName: 'addNum' },
  //   { filterRange: 1, minVal: '10', maxVal: '', fieldName: 'transactionCount' },
  //   { filterRange: 1, minVal: '', maxVal: '', fieldName: 'diff1Day' },
  //   { filterRange: 1, minVal: '', maxVal: '', fieldName: 'increasePrice' }
  // ]
}

// 下拉加载
function loadList() {
  if (pageQuery.value.nextId) {
    getPage('load')
  }
}

// 切换在售价格展示平台
const changePlatform = val => {
  useCookie('SDT_PlatformList').value = val
}

// 切换挂刀比例排序依据
const changeKnifeRatio = val => {
  useCookie('SDT_KnifeRatioSort').value = val
}

// 重置列表设置数据
const resetPopoverData = () => {
  useCookie('SDT_PlatformList').value = ['steam', 'c5', 'youpin', 'buff']
  useCookie('SDT_KnifeRatioSort').value = 'purchaseBest'

  popverVisible.value = false
}
</script>

<style lang="scss">
@import url('~/assets/styles/_color.scss');

.my-suggestion-popper {
  margin: -12px 0;

  .el-autocomplete-suggestion__wrap {
    padding: 2px 0;
  }

  .el-autocomplete-suggestion li {
    padding: 8px 10px;
    line-height: 20px;

    &:hover {
      background: transparent;

      span {
        color: $text-black-color;
      }
    }
  }

  .el-popper__arrow {
    display: none;
  }
}

.my-el-dropdown3 {
  min-width: 140px;
  font-size: 14px;
  color: $light-gray-color;
  background: $white-color;
  border: 1px solid $border-gray-color;
  border-radius: 10px;
  margin-top: -12px;
  margin-bottom: -12px;

  .el-popper__arrow {
    display: none;
  }

  .el-dropdown-menu {
    padding: 0;

    &__item {
      padding: 8px 10px;
      font-size: 14px;
      color: $light-gray-color;

      &:hover {
        color: $text-black-color;
        background: $border-gray-color;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import url('~/assets/styles/_color.scss');
.items-container {
  border-radius: 10px;
  overflow: hidden;
  .items-menu {
    border-bottom: 1px solid $border-white-color;

    .tag {
      width: 64px;
      height: 36px;
      line-height: 36px;
      color: $light-gray-color;
      text-align: center;
      background: $bg-blue-color;
      border-radius: 4px;
      border: 1px solid $bg-blue-color;
      margin-left: 4px;
      cursor: pointer;

      &.active,
      &:hover {
        color: $primary-color;
        background-color: $white-color;
        border-color: $primary-color;
      }
    }
  }

  :deep(.el-tabs) {
    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item {
      height: 72px;
      font-size: 16px;
      padding: 0 15px;
      // color: #000;
      // margin: 0 15px;
    }

    .el-tabs__active-bar {
      height: 3px;
      border-radius: 2px;
    }

    .el-tabs__nav-wrap::after {
      height: 0;
    }
  }
  .keyword-container {
    .filter-btn {
      &:hover {
        * {
          color: $primary-color;
        }
      }
    }
    &:deep(.el-input__wrapper) {
      // width: 320px;
      // height: 40px;
      width: 270px;
      height: 40px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #0252d9;
      padding-right: 0;
      position: relative;
      .el-input__suffix {
        height: 40px;
      }
      // .el-input-clear {
      //   display: none !important;
      // }

      .el-input__inner {
        padding-right: 15px;
      }

      .el-input__clear {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
      }
    }
  }
  .filter-container {
    background-color: #fff;

    .filter-item {
      // width: 160px;
    }

    .filter-button {
      height: 36px;

      &:first-child:focus,
      &:first-child:hover {
        // color: $primary-color;
      }
    }
  }

  .filter-param {
    .param-item {
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #dddfe7;
    }

    .param-button {
      height: 36px;
      border-radius: 10px;
      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
  }

  .items-content {
    background-color: #fff;

    .items-table-title {
      // background-color: #f5f8fd;
      // color: $light-gray-color;
      border-top: 1px solid #eff1f5;
      border-bottom: 1px solid #eff1f5;
    }
    .items-table-list {
      background-color: #fff;
      * {
        transition: all 0.3s;
      }
      .items-table-item {
        min-height: 115px;
        border-bottom: 1px solid #eff1f5;

        &:hover {
          background-color: #f5f8fd;
          .item-img {
            background-color: #fff;
            img {
              transform: scale(1.1);
            }
          }
        }
        .item-img {
          // background: url(~/assets/images/common/common-item-bg.png) center no-repeat;
          background-color: #f7f9ff;
          background-size: cover;
        }
      }
    }
    .items-table-c1 {
      width: 295px;
    }
    .items-table-c2 {
      width: 95px;
    }
    .items-table-c3 {
      width: 100px;
    }
    .items-table-c4 {
      width: 105px;
    }
    .items-table-c5 {
      width: 90px;
    }
    .items-table-c6 {
      width: 90px;
    }
    .items-table-c7 {
      width: 100px;
    }
    .items-table-c8 {
      width: 135px;
    }
    .items-table-c9 {
      width: 160px;
    }
    .items-table-c10 {
      width: 100px;
    }
    .items-table-c11 {
      width: 90px;
    }
  }

  .el-dropdown-link {
    min-width: 140px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: $light-gray-color;
    padding: 0 10px;
    text-align: center;
    background: $white-color;
    border: 1px solid $border-gray-color;
    border-radius: 10px;
    outline: 0 none !important;
    position: relative;

    &.active,
    &:hover {
      color: $primary-color;
      border-color: $primary-color;
    }

    .arrow {
      width: 0;
      height: 0;
      border-bottom: 12px solid $border-gray-color;
      border-left: 12px solid transparent;
      position: absolute;
      right: 6px;
      bottom: 6px;
    }
  }
}
</style>
