const indexFilterConfig = {
  platformDefaultData: {
    selected: {
      val: 'all',
      label: '全部'
    },
    list: [
      {
        val: 'all',
        label: '全部'
      },
      {
        val: 'YOUPIN',
        label: '悠悠'
      },
      {
        val: 'BUFF',
        label: 'BUFF'
      },
      {
        val: 'C5',
        label: 'C5GAME'
      }
    ]
  },
  // addNumDefaultData: {
  //   val: {
  //     minVal: null,
  //     maxVal: null
  //   }
  // },
  sellNumsDefaultData: {
    val: {
      minVal: 30,
      maxVal: null
    }
  },
  transactionCountDefaultData: {
    val: {
      minVal: 15,
      maxVal: null
    },
    dayType: 2
  },
  priceDefaultData: {
    val: {
      minVal: 100,
      maxVal: null
    }
  },
  diff1DayDefaultData: {
    val: {
      minVal: null,
      maxVal: null
    },
    dayType: 1
  },
  increasePriceDefaultData: {
    val: {
      minVal: null,
      maxVal: null
    },
    dayType: 1
  }
}

export default indexFilterConfig
